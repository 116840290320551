import React, { useContext, useEffect, useState } from "react";
import SVG from "react-inlinesvg";

import {
  ImageContainer,
  MemoizedVideoContainer,
  PureCarousel,
  SkeletonBlock,
  VideoModal,
} from "../../../components";

import Viewport from "../../../hooks/Viewport";
import MainContext from "../../../contexts/Main/Main.context";

import { HALF_VIEW_WIDTH } from "../../../../configs/images";
import { IconPlayCircledBig } from "../../../../static";

interface CarouselContainerProps {
  mediaText: string;
  modalMedia: UploadFile;
  selectedMedia: UploadFile[];
  selectedFiltersLength: number;
  selectedProductExists: boolean;
  mediaBlackOpacityPercent: number;
  mediaLink: string;
  mediaTranscriptUrl: string;
  id?: string;
}

const CarouselContainer = ({
  mediaText,
  modalMedia,
  selectedMedia,
  selectedFiltersLength,
  selectedProductExists,
  mediaBlackOpacityPercent,
  mediaLink,
  mediaTranscriptUrl,
  id,
}: CarouselContainerProps) => {
  // STATES

  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);

  // HOOKS
  const { width } = Viewport();
  const isMobile = width < 1024;
  const mobileImageHeight = width < 768 ? "220px" : "440px";

  const {
    state: {
      navbar: { height },
    },
  } = useContext(MainContext);

  // EFFECTS

  useEffect(() => {
    setLoading(false);
    return () => {};
  }, []);

  return loading ? (
    <SkeletonBlock className="min-h-[220px] bg-outer-grayscale-20" fullHeight />
  ) : (
    <>
      <div className={`relative flex flex-col justify-center`}>
        {selectedFiltersLength < 1 && (
          <>
            {mediaText && (
              <p className="absolute left-1/2 top-8 z-20 w-[90vw] -translate-x-1/2 text-center text-2xl text-outer-alpine-white md:top-28 md:w-[70%] md:text-4xl">
                {mediaText}
              </p>
            )}
            {modalMedia &&
              (Object.keys(modalMedia).length && !modalVisible ? (
                <div className="absolute left-1/2 top-1/2 z-20 -translate-x-1/2 cursor-pointer md:-translate-y-1/2">
                  {/* @ts-ignore: Types bug solved in the next React release */}
                  <SVG
                    src={IconPlayCircledBig}
                    className="w-16 fill-outer-florescent-yellow md:w-40"
                    onClick={() => setModalVisible(true)}
                  />
                </div>
              ) : (
                <VideoModal
                  modalData={{
                    media: modalMedia,
                    mediaLink: mediaLink,
                    mediaTranscriptUrl: mediaTranscriptUrl,
                  }}
                  onClick={() => setModalVisible(false)}
                />
              ))}
          </>
        )}
        {!selectedFiltersLength && (
          <div
            className="absolute inset-0 z-10 bg-outer-black"
            style={{ opacity: mediaBlackOpacityPercent / 100 }}
          />
        )}
        {!selectedProductExists && (
          <div
            className="border-b border-solid border-outer-grayscale-40"
            style={{
              height: isMobile
                ? mobileImageHeight
                : `calc(100vh - ${height}px)`,
            }}
          >
            <ImageContainer
              src={selectedMedia[0].url}
              alt={selectedMedia[0].alternativeText}
              ratio={selectedMedia[0].width / selectedMedia[0].height}
              className="!h-full object-center"
              sizes={HALF_VIEW_WIDTH}
              lazyLoad
              imageObjectFit="cover"
              fullHeight
            />
          </div>
        )}
        {selectedProductExists && (
          <PureCarousel showArrows showDots innerDots={isMobile} id={id}>
            {selectedMedia.map((media, i) => (
              <div
                className="flex flex-col justify-center border-b border-solid border-outer-grayscale-40 object-center transition-all"
                style={{
                  height: isMobile
                    ? mobileImageHeight
                    : `calc(100vh - ${height}px)`,
                }}
                key={`media-${media.url}`}
              >
                {media.provider_metadata?.resource_type == "video" ? (
                  <MemoizedVideoContainer
                    videoSrc={media.url}
                    ratio={1}
                    className="!h-full"
                  />
                ) : (
                  <ImageContainer
                    src={media.url}
                    alt={media.alternativeText}
                    ratio={media.width / media.height}
                    className="!h-full object-center"
                    sizes={HALF_VIEW_WIDTH}
                    lazyLoad
                    imageObjectFit={
                      isMobile || media.lifestyle ? "cover" : "contain"
                    }
                    fullHeight
                  />
                )}
              </div>
            ))}
          </PureCarousel>
        )}
      </div>
    </>
  );
};

export default CarouselContainer;
